import React from 'react';
import { useTranslation } from 'next-i18next';
import { LangType } from '../Header/types';
import styles from './index.module.scss';
import Popup from '../../../base/Popup';
import { CloseIcon, LoginOutIcon, OrderIcon } from '@/components/Icon';
import ArrowRightIcon from '@public/imgs/login/rightArrow.png';
import Image from 'next/image';
import router from 'next/router';
import PATHS from '@/utils/paths';
export type IProps = {
  type: 'menu' | 'lang';
  isOpen: boolean;
  toggleDrawer: () => void;
  langList: Array<LangType>;
  lang: LangType;
  handleChangeLanguage: (type: LangType) => void;
  onLogout: () => void;
};

const TopBarFnPopup = (props: IProps) => {
  const { isOpen, toggleDrawer, type, langList, lang, handleChangeLanguage, onLogout } = props;
  const { t } = useTranslation('mobile');
  const { t: OrderT } = useTranslation('home');
  const MentList = [
    {
      icon: <OrderIcon className="h-[20px] w-[20px]" />,
      btnText: OrderT('我的订单'),
      handleClick: () => {
        router.push(PATHS.orderCenter);
        toggleDrawer();
      },
    },
    {
      icon: <LoginOutIcon className="h-[20px] w-[20px]" />,
      btnText: t('进线用户链路-退出登录'),
      handleClick: () => onLogout(),
    },
  ];
  return (
    <Popup
      visible={isOpen}
      onClose={() => {
        toggleDrawer();
      }}
    >
      {type === 'menu' ? (
        <div className="rounded-[12px] bg-white">
          <div className="relative flex items-center justify-center border-b-[1px] border-solid border-b-[#EBECFA] px-[32px] py-[16px]">
            <p className="text-[20px] text-[#0A0A0A]">{t('进线用户链路-更多功能')}</p>
            <CloseIcon className="absolute right-[4px] top-[4px] m-[4px] h-[20px] w-[20px]" onClick={toggleDrawer} />
          </div>
          <div className="flex flex-col gap-[16px] px-[16px] pb-[24px] pt-[16px]">
            {MentList.map(({ icon, btnText, handleClick }) => {
              return (
                <div
                  key={btnText}
                  className="box-border flex w-full items-center justify-between rounded-lg border-[1.5px] border-solid border-[#f0f0f0] bg-white p-[16px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick();
                  }}
                >
                  <div className="flex h-[20px] w-full  justify-start ">
                    {icon}
                    <p className="ml-[8px] text-[16px] text-[#0A0A0A]">{btnText}</p>
                  </div>
                  <Image className="h-[20px] w-[20px]" width={20} height={20} src={ArrowRightIcon} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.top}>
            <p className={styles.title}>{t('进线用户链路-语言切换')}</p>
            <div className={styles.close} onClick={toggleDrawer} />
          </div>
          <div className={styles.list}>
            {langList.map((item) => {
              return (
                <div
                  className={`${styles.item} ${item === lang ? styles.act : ''}`}
                  key={item}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeLanguage(item);
                  }}
                >
                  <p>{item === 'en' ? 'English' : '中文'}</p>
                  <div className={styles.arrow} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Popup>
  );
};

export default TopBarFnPopup;
