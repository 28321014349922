import { AxiosRequestConfig } from 'axios';
import client from './axios';
import { ContentData } from './types';
import {
  BindOtherWayReq,
  CountriesRes,
  EmailCodeLoginReq,
  EmailCodeLoginRes,
  LoginRes,
  SendEmailCodeReq,
  V2ForgetPwdReq,
  V2LoginReq,
  V2RegisterReq,
  V2SendCodeReq,
  V2VerifyCodeReq,
  WechatLoginReq,
  OthersLoginMethodRes,
  EmailBindRes,
  EmailBindReq,
  refreshTokenV2Req,
} from './types/login';
import { COMMON_API_HOST as COMMON_API } from '@/utils/env';
import { getAPIBaseUrl } from '@/utils/url';

const COMMON_API_HOST = getAPIBaseUrl(COMMON_API);

export const sendLoginEmailCode = (type: 'register' | 'login', data: SendEmailCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/auth/v2/email/send_code/login/${type}`, data)
    .then((res) => res.content);
};

export const sendEmailCode = (data: SendEmailCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/auth/v2/email/send_code`, data)
    .then((res) => res.content);
};

export const loginOrRegisterByEmail = (data: EmailCodeLoginReq, config?: AxiosRequestConfig): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/app/email_verification_code/login', data, config);
};

export const loginByEmail = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/app/email_verification_code/login/v2', data);
};

export const loginByPassword = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/email', data);
};

export const registerAccount = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/register', data);
};

export const forgetPassword = (data: Omit<EmailCodeLoginReq, 'email'>): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/recover_password', data);
};

export const getTokenByJsCode = (data: WechatLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/login/wechat/applet', data);
};

export const getTokenByRefreshToken = (): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/api/authorized/refresh_token');
};

export const getTokenByRefreshTokenForPayment = (orderNumber: string): Promise<LoginRes> => {
  return client.post(`/api/acc/order/switch/${orderNumber}`);
};

export const getTokenByRefreshTokenV2 = (data: refreshTokenV2Req): Promise<LoginRes> => {
  const url = data?.refreshToken ? '/api/authorized/refresh_token_V2' : '/api/authorized/refresh_token';
  return client.post<LoginRes, LoginRes>(url, data);
};

export const switchStudent = (studentId: string) => {
  return client.post<LoginRes, LoginRes>(`/api/acc/account/switch/${studentId}`);
};

export const checkHasPwd = () => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>('/api/acc/account/already_set_password')
    .then((res) => res.content);
};

export const getCountryList = () => {
  return client
    .get<ContentData<CountriesRes>, ContentData<CountriesRes>>(`/public/open/countries/zh/ddl/local`)
    .then((res) => res.content);
};

export const loginByPhone = (data: V2LoginReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/login`, data);
};

export const sendVerifyCodeV2 = (data: V2SendCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/authentication/verify/send_code/authentication`, data)
    .then((res) => res.content);
};

export type VerificationType = 'EMAIL' | 'MOBILE_PHONE';

export interface SendVerifyCodeV3 {
  verificationType: VerificationType;
  target: string | null;
  captchaVerification?: string;
  authenticationWay?: string;
}

export const sendVerifyCodeV3 = (api: string, data: SendVerifyCodeV3) => {
  return client.post<EmailCodeLoginRes, EmailCodeLoginRes>(`${api}`, data).then((res) => res.content);
};

export interface CheckVerifyCodeReq {
  verificationCode: string;
  target: string;
  verificationType: VerificationType;
}

export interface CheckVerifyCodeRes {
  content: boolean;
}

export const CheckVerifyCode = (data: CheckVerifyCodeReq) => {
  return client.post<CheckVerifyCodeRes, CheckVerifyCodeRes>('public/authentication/verify', data);
};

export const checkForgetPwd = (type: 'EMAIL' | 'MOBILE', target: string | null) => {
  return client
    .post<any, any>(`/public/authentication/password_forgot`, { target, contactType: type })
    .then((res) => res.content);
};

export const forgetPasswordV2 = (data: V2ForgetPwdReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/recover_password`, data);
};

export const registerAccountV2 = (data: V2RegisterReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/authentication/register', data);
};

export const checkPhoneExist = (data: V2RegisterReq) => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>(`/public/authentication/exist_account`, data)
    .then((res) => res.content);
};

export const checkVerifyCode = (data: V2VerifyCodeReq) => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>(`/public/authentication/verify`, data)
    .then((res) => res.content);
};

export const sendBindCode = (data: V2SendCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/authentication/verify/send_code`, data)
    .then((res) => res.content);
};

export const bindOtherWay = (data: BindOtherWayReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/bind`, data);
};

export const bindConnectWay = (data: V2SendCodeReq) => {
  return client.post<LoginRes, LoginRes>(`/api/acc/account/change/email_phone`, data);
};

export const verifyPhone = (phone: string) => {
  return client.get<ContentData<boolean>, ContentData<boolean>>(`public/open/valid_phone/${phone}`);
};
/**
 * 获取三方登录方式
 */

export const getOthersLoginMethod = () => {
  return client
    .get<ContentData<OthersLoginMethodRes>, ContentData<OthersLoginMethodRes>>('/public/open/web/properties')
    .then((res) => res.content);
};

export const bindEmail = (data: EmailBindReq) => {
  return client.post<EmailBindRes, EmailBindRes>(`${COMMON_API_HOST}/public/create/token_sign/email`, data);
};
