const DownLoadV2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M16 16.5C16.1989 16.5 16.3897 16.579 16.5303 16.7197C16.671 16.8603 16.75 17.0511 16.75 17.25C16.75 17.4489 16.671 17.6397 16.5303 17.7803C16.3897 17.921 16.1989 18 16 18H4C3.80109 18 3.61032 17.921 3.46967 17.7803C3.32902 17.6397 3.25 17.4489 3.25 17.25C3.25 17.0511 3.32902 16.8603 3.46967 16.7197C3.61032 16.579 3.80109 16.5 4 16.5H16ZM10 3.00299C10.1989 3.00299 10.3897 3.08201 10.5303 3.22266C10.671 3.36331 10.75 3.55408 10.75 3.75299V12.8122L14.3425 9.21974C14.4773 9.08498 14.6584 9.00662 14.849 9.0006C15.0395 8.99458 15.2252 9.06135 15.3683 9.18733C15.5114 9.31332 15.6011 9.48906 15.6193 9.67883C15.6374 9.86861 15.5826 10.0582 15.466 10.209L15.403 10.2795L10.5273 15.1582C10.3982 15.2875 10.2264 15.3652 10.0442 15.3768C9.86188 15.3884 9.68164 15.3331 9.53725 15.2212L9.46675 15.159L4.5955 10.296C4.46048 10.1613 4.38186 9.98017 4.37563 9.78954C4.36941 9.59891 4.43605 9.41307 4.56199 9.26983C4.68793 9.12659 4.86372 9.03672 5.05358 9.01849C5.24344 9.00027 5.43311 9.05507 5.584 9.17174L5.6545 9.23399L9.25 12.822V3.75299C9.25 3.55408 9.32902 3.36331 9.46967 3.22266C9.61032 3.08201 9.80109 3.00299 10 3.00299Z"
        fill="black"
      />
    </svg>
  );
};

export default DownLoadV2;
