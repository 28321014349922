import React from 'react';

const CalendarV2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 1C4.5 0.723858 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723858 3.5 1V1.25H1.75C1.19772 1.25 0.75 1.69772 0.75 2.25V10C0.75 10.5523 1.19772 11 1.75 11H10.25C10.8023 11 11.25 10.5523 11.25 10V2.25C11.25 1.69772 10.8023 1.25 10.25 1.25H8.5V1C8.5 0.723858 8.27614 0.5 8 0.5C7.72386 0.5 7.5 0.723858 7.5 1V1.25H4.5V1ZM10.25 4.25V2.25H8.5V3C8.5 3.27614 8.27614 3.5 8 3.5C7.72386 3.5 7.5 3.27614 7.5 3V2.25H4.5V3C4.5 3.27614 4.27614 3.5 4 3.5C3.72386 3.5 3.5 3.27614 3.5 3V2.25H1.75V4.25H10.25ZM6.5 8.5C6.5 8.22386 6.72386 8 7 8H8.5C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9H7C6.72386 9 6.5 8.77614 6.5 8.5ZM3.5 8C3.22386 8 3 8.22386 3 8.5C3 8.77614 3.22386 9 3.5 9H5C5.27614 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.27614 8 5 8H3.5ZM6.5 6.5C6.5 6.22386 6.72386 6 7 6H8.5C8.77614 6 9 6.22386 9 6.5C9 6.77614 8.77614 7 8.5 7H7C6.72386 7 6.5 6.77614 6.5 6.5ZM3.5 6C3.22386 6 3 6.22386 3 6.5C3 6.77614 3.22386 7 3.5 7H5C5.27614 7 5.5 6.77614 5.5 6.5C5.5 6.22386 5.27614 6 5 6H3.5Z"
        fill="white"
      />
    </svg>
  );
};

export default CalendarV2;
