const Order = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.75 7.28553V15C16.75 15.9665 15.9665 16.75 15 16.75H5C4.0335 16.75 3.25 15.9665 3.25 15V5C3.25 4.0335 4.0335 3.25 5 3.25H12.7145C13.1786 3.25 13.6237 3.43437 13.9519 3.76256L16.2374 6.0481C16.5656 6.37629 16.75 6.8214 16.75 7.28553Z"
        stroke="#0A0A0A"
        strokeWidth="1.5"
      />
      <path
        d="M13.0005 3.25V5.75C13.0005 6.44036 13.5601 7 14.2505 7H16.7505"
        stroke="#0A0A0A"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M7.34621 8.90157H12.6495"
        stroke="#0A0A0A"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.34621 12.6516H12.6495"
        stroke="#0A0A0A"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Order;
