import Image from 'next/image';
import Close from '@/components/Icon/Close';
import { noticeImg } from '../Header/constants';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import CalendarModal, { ICalendarModalProps } from '../CalendarModal';
import { checkSubscribe } from '@/api/home';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';

export type ICalendarTipRef = {
  visible: boolean; // needCalendarTip为true
  close?: (visible: boolean) => void;
};
const CalendarTip = ({ visible, close }: ICalendarTipRef) => {
  const { t } = useTranslation('common');
  const [isSubscribe, setIsSubscribe] = useState(false);
  const { traceEventWithAccount } = useTraceEventWithAccount();
  const calendarModalRef = useRef<ICalendarModalProps | null>(null);

  const getSubscribe = async () => {
    const result = await checkSubscribe();
    const isShow = !result && visible;
    setIsSubscribe(isShow);
    !isShow && onClose();
    if (isShow) {
      traceEventWithAccount({
        _event: 'O_topSubscirbeCalendarInfo',
        needFamilyId: true,
      });
    }
  };

  useEffect(() => {
    visible && getSubscribe();
  }, [visible]);
  const onClose = () => {
    traceEventWithAccount({
      _event: 'O_topSubscirbeCalendarInfoClose',
      needFamilyId: true,
    });
    close?.(visible);
    setIsSubscribe(false);
  };

  return (
    <>
      {isSubscribe && (
        <div className="fixed top-[49px] z-[10] box-border flex h-12 w-full items-center justify-center bg-[#FFF9E0] px-3 py-2 text-[12px] lg:relative lg:top-0 lg:text-[15px]">
          <div className="flex h-full w-[960px] justify-between">
            <div className="flex items-center justify-center">
              <Image src={noticeImg} width={24} height={24} alt="WuKong" />
              <span className="ml-3 max-w-[200px] font-medium text-[#0A0A0A] lg:ml-4 lg:max-w-full">
                {t('将课程同步到日历')}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <span
                className="mr-3 cursor-pointer font-medium text-[#4395DE] lg:mr-8"
                onClick={() => {
                  traceEventWithAccount({
                    _event: 'O_subscribeCalendarClick',
                    needFamilyId: true,
                  });
                  calendarModalRef.current?.onShow();
                }}
              >
                {t('日历-订阅按钮')}
              </span>
              <Close className="h-4 w-4 cursor-pointer lg:h-6 lg:w-6" color="#666" onClick={onClose} />
            </div>
          </div>
        </div>
      )}
      <CalendarModal ref={calendarModalRef} />
    </>
  );
};

export default CalendarTip;
