const Close = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.05078 4.35938L11.9704 12.279"
        stroke={props.color || '#0A0A0A'}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M4.05078 12.2812L11.9704 4.36165"
        stroke={props.color || '#0A0A0A'}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Close;
