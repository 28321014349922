import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Mask } from '@/components';
import Close from '@/components/Icon/Close';
import QRCode from 'qrcode.react';
import { useTranslation } from 'next-i18next';
import Button from '@/components/ButtonV2';
import cls from 'classnames';
import router from 'next/router';
import useUser from '@/store/useUser';
import { wukongQrCodeLogo } from './constants';
import { AUTH_REDIRECT_URI } from '@/utils/env';
import PATHS from '@/utils/paths';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';

export interface ICalendarModalProps {
  onShow: () => void;
  onHide: () => void;
}
const imageSettings = {
  src: wukongQrCodeLogo.src,
  height: 45,
  width: 45,
  excavate: true,
};

const CalendarModal = (_: unknown, ref: React.Ref<ICalendarModalProps>) => {
  const { t, i18n } = useTranslation('common');
  const { account } = useUser();
  const isZh = i18n.language === 'zh';
  const [inProp, setInProp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('');
  const { traceEventWithAccount } = useTraceEventWithAccount();

  useImperativeHandle(ref, () => ({
    onShow: () => {
      setVisible(true);
    },
    onHide: () => setVisible(false),
  }));

  useEffect(() => {
    setInProp(visible || false);
  }, [visible]);

  useEffect(() => {
    const url = `${AUTH_REDIRECT_URI}${PATHS.calendarMiddlePage}?familyId=${account?.userFamilyId}&dsSource=student_center`;
    setQrCodeValue(url);
  }, [account]);

  return (
    <>
      {visible ? (
        <Mask zIndex={99} className={cls(isZh ? 'font-MiSans' : 'font-Figtree')}>
          <CSSTransition in={inProp} timeout={600}>
            <div className="absolute bottom-0 left-0 right-0 top-0 z-[999] m-auto flex h-[316px] w-[311px] flex-col overflow-hidden rounded-xl bg-white text-[#0A0A0A] lg:h-[492px] lg:w-[468px]">
              <Close
                className="absolute right-0 top-0 z-40 hidden cursor-pointer p-2 lg:block"
                onClick={() => setVisible(false)}
              />
              <div className="text-[#0A0A0A]">
                <header
                  className={cls(
                    'border-b border-solid border-[#f0f0f0] p-4 text-center text-[20px] font-semibold lg:px-8 lg:py-6',
                  )}
                >
                  {t('同步日历')}
                </header>
                <section className="px-4">
                  <p className="mt-6 hidden text-wrap break-words px-6 text-center text-[16px] lg:mt-8 lg:block">
                    {t('同步日历描述-PC')}
                  </p>
                  <p className="mt-6 block text-wrap break-words text-center text-[16px] lg:mt-8 lg:hidden">
                    {t('同步日历描述-Mobile')}
                  </p>
                  <div className="mt-6 flex flex-col items-center justify-center gap-8">
                    <div className="box-border hidden h-[200px] w-[200px] rounded-xl border-2 border-solid border-[#f0f0f0] bg-white p-4 lg:block">
                      <QRCode value={qrCodeValue} size={168} imageSettings={imageSettings as any} level="M" />
                    </div>
                    <Button
                      className="hidden !h-[52px] min-w-[167px] text-[16px] lg:block"
                      onClick={() => setVisible(false)}
                    >
                      {t('同步日历-完成')}
                    </Button>
                    <Button
                      className="block !h-[48px] w-full text-[16px] lg:hidden"
                      onClick={() => {
                        traceEventWithAccount({
                          _event: 'O_subscribeCalendarConfirmClick',
                          needFamilyId: true,
                        });
                        router.push({
                          pathname: PATHS.calendarMiddlePage,
                          query: {
                            familyId: account?.userFamilyId,
                            dsSource: 'student_center',
                          },
                        });
                      }}
                    >
                      {t('同步日历-同步')}
                    </Button>
                  </div>
                  <div
                    className="mt-5 block text-center text-[16px] text-[#999] lg:hidden"
                    onClick={() => setVisible(false)}
                  >
                    {t('同步日历-取消')}
                  </div>
                </section>
              </div>
            </div>
          </CSSTransition>
        </Mask>
      ) : null}
    </>
  );
};
export default forwardRef(CalendarModal);
