const LoginOut = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M2.70837 3.3335C2.70837 2.98832 2.9882 2.7085 3.33337 2.7085H10.1042C10.4494 2.7085 10.7292 2.98832 10.7292 3.3335V3.54183C10.7292 3.88701 10.4494 4.16683 10.1042 4.16683H4.79171C4.44653 4.16683 4.16671 4.44665 4.16671 4.79183V15.2085C4.16671 15.5537 4.44653 15.8335 4.79171 15.8335H10.1042C10.4494 15.8335 10.7292 16.1133 10.7292 16.4585V16.6668C10.7292 17.012 10.4494 17.2918 10.1042 17.2918H3.33337C2.9882 17.2918 2.70837 17.012 2.70837 16.6668V3.3335Z"
        fill="#0A0A0A"
      />
      <path
        d="M13.2039 6.49424C13.448 6.25016 13.8437 6.25016 14.0878 6.49424L17.1518 9.55822C17.3959 9.8023 17.3959 10.198 17.1518 10.4421L14.0878 13.5061C13.8437 13.7502 13.448 13.7502 13.2039 13.5061L13.0566 13.3588C12.8125 13.1147 12.8125 12.719 13.0566 12.4749L14.8022 10.7293H6.97921C6.63403 10.7293 6.35421 10.4495 6.35421 10.1043V9.896C6.35421 9.55082 6.63403 9.271 6.97921 9.271H14.8022L13.0566 7.52544C12.8125 7.28136 12.8125 6.88563 13.0566 6.64155L13.2039 6.49424Z"
        fill="#0A0A0A"
      />
    </svg>
  );
};

export default LoginOut;
